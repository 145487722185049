import Modal from './modal';

export default function modalInit() {
  const modals = {};
  const modalOpenTriggers = Array.from(document.querySelectorAll(['[data-modal-trigger]']));

  modalOpenTriggers.forEach((trigger) => {
    // Creating instance of modal
    const name = trigger.dataset.modalTrigger;
    const modal = new Modal({
      selector: `[data-modal="${name}"]`,
    });

    // Saving modal in modals object for future instance use
    modals[name] = modal;

    // Adding event listener for trigger
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      modal.open();
    });
  });

  // Opening modal 1 on the fly for demo purpose
  if (modals['modal-1']) {
    modals['modal-1'].open();
  }
}
