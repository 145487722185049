/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import Swiper, { Navigation, Pagination, Autoplay, A11y, Keyboard } from 'swiper';
import 'swiper/css/bundle';

Swiper.use([Navigation, Pagination, Autoplay, A11y, Keyboard]);

export default function sliderInit() {
  const sliderElement = document.querySelector('.js-swiper');

  if (sliderElement) {
    let options = {
      updateOnWindowResize: true,
      watchOverflow: true,
      // slidesPerView: 'auto',
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      slidesPerView: 1.1,
      spaceBetween: 12,
      a11y: {
        enabled: true,
        prevSlideMessage: 'Next',
        nextSlideMessage: 'Previous',
        paginationBulletMessage: 'To {{index}}',
      },
      keyboard: {
        enabled: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1.2,
        },
      },
    };

    const pagination = sliderElement.getAttribute('data-pagination');
    if (pagination) {
      options = {
        ...options,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      };
    }

    const navigation = sliderElement.getAttribute('data-navigation');
    if (navigation) {
      options = {
        ...options,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    const autoplay = sliderElement.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = sliderElement.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: true };
    }

    const slider = new Swiper(sliderElement, options);

    // let slider;

    // if (window.innerWidth >= 768 && slider === undefined) {
    //   slider = new Swiper(sliderElement, options);
    // }

    // window.addEventListener('resize', () => {
    //   if (window.innerWidth < 768 && slider !== undefined) {
    //     slider.destroy(true, true);
    //     slider = undefined;
    //   } else if (window.innerWidth >= 768 && slider === undefined) {
    //     slider = new Swiper(sliderElement, options);
    //   }
    // });
  }
}
