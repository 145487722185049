import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Slovenian } from 'flatpickr/dist/l10n/sl';

export default function DatePicker() {
  const pickers = document.querySelectorAll('.js-date-picker');
  const options = {
    dateFormat: 'd.m.Y',
    mode: 'range',
    locale: Slovenian,
    disableMobile: 'true',
  };

  pickers.forEach((picker) => {
    const input = picker.querySelector('input');
    flatpickr(input, options);
  });
}
