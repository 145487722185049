import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import closestParent from '../../assets/js/helpers/closest-parent';

export default function headerInit() {
  const hamburgers = document.querySelectorAll('.js-hamburger');
  hamburgers.forEach((hamburger) => {
    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('active');
      const header = closestParent(hamburger, '.js-header');
      header.classList.toggle('mobile');

      if (header.classList.contains('mobile')) {
        disableBodyScroll(header);
      } else {
        enableBodyScroll(header);
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
          if (header.classList.contains('mobile')) {
            header.classList.remove('mobile');
            hamburger.classList.remove('active');
            enableBodyScroll(header);
          }
        }
      });
    });
  });

  const header = document.querySelector('.js-header');
  const observerTarget = document.querySelector('.js-observer-target');
  if (observerTarget) {
    const isHomePage = document.querySelector('body').classList.contains('home');
    const homeHero = document.querySelector('.home-hero');
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    let rootMargin = '-130px';
    let threshold = 1.0;

    if (width < 1395) {
      threshold = 0.8;
    }

    if (width < 1151) {
      threshold = 0.6;
    }

    if (width < 1024) {
      threshold = 0.3;
    }

    if (width < 541) {
      rootMargin = '-150px';
      threshold = 0.0;
    }

    if (isHomePage) {
      rootMargin = '50px';
      threshold = 1.0;
    }

    const options = {
      rootMargin,
      threshold,
    };

    const handler = (entries) => {
      if (!entries[0].isIntersecting) {
        header.classList.add('-scrolling');

        if (isHomePage) {
          homeHero.classList.add('-scrolling');
        }
      } else {
        header.classList.remove('-scrolling');

        if (isHomePage) {
          homeHero.classList.remove('-scrolling');
        }
      }
    };

    const observer = new IntersectionObserver(handler, options);

    observer.observe(observerTarget);
  }
}
