export default function infoCard() {
  const cards = document.querySelectorAll('.info-card');
  const wrap = document.querySelector('.info-card-wrap');

  cards.forEach((card) => {
    card.addEventListener('click', () => {
      if (card.classList.contains('active')) {
        card.classList.remove('active');
        wrap.classList.remove('expanded');
      } else {
        cards.forEach((el) => {
          el.classList.remove('active');
        });

        card.classList.add('active');
        wrap.classList.add('expanded');
      }
    });
  });
}
