/* eslint-disable no-unused-vars */
import './assets';
import colorListInit from '../../../utils/colors-list';
import counterInit from '../../components/counter/counter';
import headerInit from '../../components/header/header';
import selectInit from '../../components/form/select/select';
import checkboxRadioInit from '../../components/form/checkbox-radio/checkbox-radio';
import sliderInit from '../../components/slider/slider';
import scrollbarInit from '../../components/scrollbar/scrollbar';
import modalInit from '../../components/modal/modalInit';
import datePickerInit from '../../components/form/date-picker/date-picker';
import formInit from './form';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import infoCard from '../../components/info-card/info-card';

// Cookie consent
async function cookies() {
  const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
  const consent = new CookieConsent(cookieOptions);
}

colorListInit();
counterInit();
headerInit();
selectInit();
checkboxRadioInit();
sliderInit();
scrollbarInit();
modalInit();
datePickerInit();
formInit();
cookies();
infoCard();
